exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-english-jsx": () => import("./../../../src/pages/english.jsx" /* webpackChunkName: "component---src-pages-english-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-menu-jsx": () => import("./../../../src/pages/menu.jsx" /* webpackChunkName: "component---src-pages-menu-jsx" */),
  "component---src-pages-scholarship-jsx": () => import("./../../../src/pages/scholarship.jsx" /* webpackChunkName: "component---src-pages-scholarship-jsx" */),
  "component---src-pages-teknologi-jsx": () => import("./../../../src/pages/teknologi.jsx" /* webpackChunkName: "component---src-pages-teknologi-jsx" */)
}

